import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StaticQuery, graphql } from 'gatsby';

/*
    taken from https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
 */

function renderImage(file, title) {
    return <GatsbyImage className="catalog-image" image={file.node.childImageSharp.gatsbyImageData} alt={title} />
}

const CatalogImg = function (props) {
    return <StaticQuery
        query={graphql`
      query {
      images: allFile(filter:{ extension: { regex: "/jpeg|jpg|png|gif|svg/"}}) {
      edges {
        node {
          extension
          relativePath
          childImageSharp {
                gatsbyImageData(
                layout: CONSTRAINED
                width: 400
                )
            }
        }
      }
    }
    }
    `}
        render={({ images }) => renderImage(images.edges.find(image => image.node.relativePath === props.src), props.title)}
    />
}
export default CatalogImg;