import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Catalog from "../components/catalog"
import Teaser from "../components/teaser"
import ServiceText from "../components/service-text"

import LightBoxGallery from "../components/react/lightbox-gallery";

import ImageTriple from "../components/images/image-triple"

import Avatar from "../components/avatar"

const IndexPage = () => (
    <Layout>
        <Seo title="Finzel+Schuck Sticken, Drucken, Nähen" />
        <div id='home'>
            <Banner title='Finzel & Schuck Produktions GmbH & Co. KG'>
                &bdquo;Qualität beginnt damit, die Zufriedenheit des Kunden in das Zentrum des Denkens zu stellen.&rdquo;<span className="banner-author">- John F. Akers</span>
            </Banner>
        </div>


        <div id='ueberuns'>
            <div>
                <ServiceText headline='Wer sind wir?' className='text-about'>
                    Seit 1990 fertigt <h1>FINZEL+SCHUCK Textilien und Textilveredelungen</h1> mit einem hohen Qualitätsanspruch.<br /><br />
                    Unsere handwerklichen Fähigkeiten lassen feinste Stickereien und hochwertige Drucke auf Textilien und Kleidung entstehen. Nachhaltiges Denken und
                    Handeln stehen in unserem Familienbetrieb seit jeher an erster Stelle. Die Zufriedenheit unserer Kunden und das Wohl unserer Mitarbeiter sind unsere Grundanliegen.
                </ServiceText>
            </div>
            <div className="text-service-wrapper container">
                <ServiceText headline='Tradition mit Zukunft.' className='text-service col-3 col-mls-1'>
                    Wir blicken auf 30 Jahre Erfahrung zurück und leben so die textile Tradition unserer Heimat weiter. Die Weiterentwicklung der Technologien lassen uns dabei immer am
                    Puls der Zeit sein. Mit modernen Maschinen und unserem Know-How können wir Ihnen auch spezielle Techniken, wie z.B. 3D-Stickereien, anbieten.
                </ServiceText>
                <ServiceText headline='Flexible Lösungen für Sie.' className='text-service col-3 col-mls-1'>
                    Wir stehen Ihnen für Ihre Aufträge als zuverlässiger Partner für individuelle und vielseitige Textilgestaltungen zur Seite. Unser fachkundiges Team berät Sie gern
                    zu Ihren Anfragen und Wünschen.
                </ServiceText>
                <ServiceText headline='Qualität zeichnet uns aus.' className='text-service col-3 col-mls-1'>
                    Unsere Textilveredelungen erfüllen höchste Qualitätsansprüche. Mit dem Auge für jedes kleine Detail setzen wir Ihre Kundenwünsche um. Ihre Zufriedenheit ist unsere
                    oberste Priorität.
                </ServiceText>
            </div>
        </div>

        <div id='stickerei'>
            <Teaser src="teaser/stickerei.jpg" title="Stickerei" textPosition="right" headline="Stickerei">
                Das Besticken von Kleidung ist seit vielen Jahrhunderten hohe handwerkliche Kunst und edelste Form der Textilveredelung. Moderne Maschinentechnik und Elektronik machen es
                heute möglich, sehr effizient und schnell zu sticken. Es erfordert, trotz technischem Fortschritts, immer ein hohes Maß an Handwerklichkeit, Qualitätssinn und Ästhetik.
                Die Mitarbeiter von FINZEL+SCHUCK vereinen diese Fähigkeiten und fertigen für Sie hochwertige, anspruchsvolle Stickereien.
            </Teaser>
            <div className="text-service-wrapper container">
                <ServiceText headline='2D-Stickerei' className='text-service col-2 col-mvp-1'>
                    Um besonders detailreiche Motive abzubilden, empfehlen wir die 2D- oder Flachstickerei. Dort lassen sich nahezu alle Farben, Formen und vereinfachte Verläufe umsetzen.
                    Besonderes Augenmerk gilt der Programmierung der Stickprogramme. Erfahrene Fachleute setzen Ihre Logos oder Motive in perfekter Qualität in ein digitales Stickformat um.
                    Gern berät Sie unser FINZEL+SCHUCK Team zu den Gestaltungsmöglichkeiten und Techniken.
                </ServiceText>
                <ServiceText headline='3D-Stickerei' className='text-service col-2 col-mvp-1'>
                    Mit der Verwendung eines speziellen Schaumstoffes wird das Motiv dreidimensional auf Textilien und Caps modelliert. Auffällig und hochwertig wirkt diese Veredelungsart.
                    Geeignet ist sie vor allem für Motive, die nicht zu viele Details beinhalten. Die 3D-Stickerei ist eine moderne Textilveredelung, die die Blicke auf sich zieht.
                    Schauen Sie sich unsere Referenzen an und lassen Sie sich begeistern.
                </ServiceText>
            </div>
            <ImageTriple src="teaser/stick1.jpg" title="Stickerei Bild 1" className="col-3" />
            <ImageTriple src="teaser/stick2.jpg" title="Stickerei Bild 2" className="col-3" />
            <ImageTriple src="teaser/stick3.jpg" title="Stickerei Bild 3" className="col-3" />
        </div>

        <div id='textildruck'>
            <Teaser src="teaser/textildruck.jpg" title="Textildruck" textPosition="right" headline="Textildruck">
                Der Textildruck in seinen vielfältigen Varianten gehört ebenfalls seit langer Zeit zur angewandten Technik der Textilveredelung. Wir, von FINZEL+SCHUCK, nutzen zahlreiche
                Varianten zum Veredeln Ihrer Textilien. Dem Transferdruck gehört dabei ein weites Feld, ebenso wie dem Direktsiebdruck, Digitaldruck und diversen Foliendrucken.
                <br /><br />
                Wir arbeiten dabei seit vielen Jahren kooperativ mit verschiedenen Partnern eng zusammen. Auch hier steht die Qualität, des jeweils am besten geeigneten Verfahrens,
                in unserem Mittelpunkt.
            </Teaser>
            <div className="text-service-wrapper container">
                <ServiceText headline='Direktdruck' className='text-service col-2 col-mvp-1'>
                    Beim Direktdruck können die jeweiligen Textilien ohne Zwischenstufe direkt mit verschiedenen Techniken veredelt werden. Der Siebdruck ist hier die am häufigsten angewendete
                    Technik und ist für mittlere und große Mengen ein kostengünstiges Verfahren. Hierbei sind Echtfarbendrucke als auch Rasterdrucke mit Farbverläufen abbildbar. Der Digitaldruck
                    wird immer häufiger als Direktverfahren angewendet und bietet die Möglichkeit, auch kleinere Losgrößen effizient herzustellen.
                </ServiceText>
                <ServiceText headline='Transferdruck' className='text-service col-2 col-mvp-1'>
                    Beim Transferdruck werden die entsprechenden Druckverfahren auf Trägerpapier- oder Folie gedruckt und dann auf das Textil mit Hitze und Druck transferiert. Vorteil ist dabei die
                    flexible Verwendung der vorgefertigten Transfers nach Bedarf. Varianten des Transferdrucks sind Siebdrucktransfers, Offsettransfers, Digitaldrucktransfers und Transferfolien.
                </ServiceText>
            </div>
        </div>

        <div id="konfektion">
            <Teaser src="teaser/konfektion.jpg" title="Textildruck" textPosition="right" headline="Konfektion">
                Die Erhaltung, Weiterentwicklung und somit die Zukunftssicherung der Fähigkeiten des Nähens und Konfektionierens ist eine bedeutende Möglichkeit nachhaltigen Produzierens. Seit 2012
                fertigen wir in unserer Manufaktur exzellente Qualitäten von T-Shirts, Poloshirts sowie Spezialanfertigungen, wie z.B. verschiedene technisch-medizinische Produkte.
                <br /><br />
                Unsere Marke Maximilian Frey steht für das perfekte Poloshirt „Made in Germany“.
            </Teaser>
        </div>

        <div id="referenzen">
            <h2>Referenzen</h2>

            <div className="container referenzen-list">
                <LightBoxGallery />
            </div>
        </div>

        <div id="kataloge">
            <div className="container">
                <h2>Kataloge</h2>
                <div className="catalog--container">
                    <Catalog name="Clique" link="../pdf/kataloge/2024/clique_katalog.pdf" linkTitle="Clique Katalog PDF" src="catalogs/clique_logo.png"></Catalog>
                    <Catalog name="Craft" link="/pdf/kataloge/2024/craft_katalog.pdf" linkTitle="Craft Katalog PDF" src="catalogs/craft_logo.png"></Catalog>
                    <Catalog name="Fare" link="/pdf/kataloge/2024/fare_katalog.pdf" linkTitle="Fare Katalog PDF" src="catalogs/fare_logo.png"></Catalog>
                    <Catalog name="Greiff Corporate" link="/pdf/kataloge/2024/greiff_corporate_katalog.pdf" linkTitle="Greiff Corporate Katalog PDF" src="catalogs/greiff_logo.png"></Catalog>
                    <Catalog name="Greiff Gastro" link="/pdf/kataloge/2024/greiff_gastro_katalog.pdf" linkTitle="Greiff Gastro Katalog PDF" src="catalogs/greiff_logo.png"></Catalog>
                    <Catalog name="Hakro" link="/pdf/kataloge/2024/hakro_katalog.pdf" linkTitle="Hakro Katalog PDF" src="catalogs/hakro_logo.png"></Catalog>
                    <Catalog name="Halfar" link="/pdf/kataloge/2024/halfar_katalog.pdf" linkTitle="Halfar Katalog PDF" src="catalogs/halfar_logo.png"></Catalog>
                    <Catalog name="James Nicholson" link="/pdf/kataloge/2024/james_nicholson_katalog.pdf" linkTitle="James Nicholson Katalog PDF" src="catalogs/james_nicholson_logo.png"></Catalog>
                    <Catalog name="Jung since 1828" link="/pdf/kataloge/2024/jung_since_1828_katalog.pdf" linkTitle="Jung since 1828 Katalog PDF" src="catalogs/jung_logo.png"></Catalog>
                    <Catalog name="Kübler" link="/pdf/kataloge/2024/kuebler_katalog.pdf" linkTitle="Kübler Katalog PDF" src="catalogs/kuebler_logo.png"></Catalog>
                    <Catalog name="Mascot" link="/pdf/kataloge/2024/mascot_katalog.pdf" linkTitle="Mascot Katalog PDF" src="catalogs/mascot_logo.png"></Catalog>
                    <Catalog name="Olymp" link="/#kataloge" linkTitle="Olymp Katalog PDF" src="catalogs/olymp_logo.png"></Catalog>
                    <Catalog name="Stanley Stella" link="/pdf/kataloge/2024/stanley_stella_katalog.pdf" linkTitle="Stanley Stella Katalog PDF" src="catalogs/stanley_stella_logo.png"></Catalog>
                </div>
            </div>
        </div>

        <div id="ansprechpartner">
            <div className="container ansprechpartner-list">
                <h2>Ansprechpartner</h2>

                <Avatar src='avatar/hschuck.jpg' title="Heidrun Schuck" name="Heidrun Schuck" job="Produktionsleitung"
                        telLink="+493722600240" telString="+49 3722 60 02-40" mail="kontakt@fsproduktion.de"/>
                <Avatar src='avatar/cbarthel.jpg' title="Cordula Barthel" name="Cordula Barthel" job="Druckerei | Stickerei | Technologie"
                        telLink="+493722600244" telString="+49 3722 60 02-44" mail="kontakt@fsproduktion.de"/>
                <Avatar src='avatar/cscherf.jpg' title="Corina Scherf" name="Corina Scherf" job="Verkauf"
                        telLink="+493722600243" telString="+49 3722 60 02-43" mail="kontakt@fsproduktion.de"/>
                <Avatar src='avatar/sboettcher.jpg' title="Silke Böttcher" name="Silke Böttcher" job="Konfektion"
                        telLink="+4937225184711" telString="+49 3722 5184711" mail="kontakt@fsproduktion.de"/>

            </div>
        </div>

    </Layout>
)

export default IndexPage
