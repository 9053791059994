import React from 'react'
import CatalogImg from '../components/images/image-catalog'
import { Link } from 'gatsby'

const Catalog = props => (
    <div className="col-4">
        <div className="catalog--wrapper">
            <div className="catalog--name">
                {props.name}
            </div>
            <div className="catalog--link">
                <Link to={props.link} target="_blank" title={props.linkTitle}>
                    <CatalogImg src={props.src} alt={props.title}></CatalogImg>
                </Link>
            </div>
        </div>
    </div>
)

export default Catalog;